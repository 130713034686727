import { useFlags } from 'flagsmith/react';
import _, { toNumber } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { SCHEDULE_CYCLE_MONTHLY } from '@lawnstarter/customer-modules/constants';
import { FeatureFlags, InstantQuoteTypes, WebRoutes } from '@lawnstarter/customer-modules/enums';
import {
  getTrackingEventSource,
  useOrderServiceViewedTracking,
} from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import {
  instantQuote_acceptQuote,
  instantQuote_generateQuote,
  instantQuote_rejectQuote,
  properties_currentPropertyIdSelector,
} from '@lawnstarter/customer-modules/stores/modules';
import { ExtendedYardLocations, QuoteLeafRemovalOptions } from '@lawnstarter/ls-constants';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import {
  Button,
  ImageUploadInput,
  Text,
  TextArea,
  TextInput,
} from '@lawnstarter/ls-react-common/atoms';
import { RadioOptionVariant, ServiceType } from '@lawnstarter/ls-react-common/enums';
import { useApiTracking, useModal } from '@lawnstarter/ls-react-common/hooks';
import { CheckboxOptionsForm, RadioOptionsForm } from '@lawnstarter/ls-react-common/molecules';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { GeneratedInstantQuoteViewer, OrderServiceHeader } from '@src/components';
import { checkboxParser, scrollToBottom } from '@src/helpers';
import {
  useAutoScrollOnFormErrors,
  useDispatch,
  useRouteNavigation,
  useSelector,
} from '@src/hooks';
import { errorService } from '@src/services';

import { StyledLeafRemovalWrapper, styles } from './styles';

import type { LeafRemovalFormData } from './types';

export function OrderLeafRemovalScreen() {
  const options = QuoteLeafRemovalOptions;

  const dispatch = useDispatch();
  const location = useLocation();
  const { navigate } = useRouteNavigation();
  const feedbackModal = useModal(ModalTemplate.FEEDBACK);

  const propertyId = useSelector(properties_currentPropertyIdSelector);
  const [showQuote, setShowQuote] = useState(false);

  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);

  const instantQuote = useSelector((state) => state.instantQuote);

  const apiTracking = useApiTracking()!;
  const featureFlags = useFlags([
    FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST,
    FeatureFlags.CUST_QUOTES_RECURRING_OPTIONS,
  ]);

  const carouselAbTest = featureFlags[FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST];

  const source = carouselAbTest.enabled
    ? getTrackingEventSource(location.state?.source, carouselAbTest.value)
    : undefined;
  useOrderServiceViewedTracking(apiTracking, ServiceType.LeafRemoval, errorService, source);

  const defaultValues: Partial<LeafRemovalFormData> = {
    desired_haulaway: true,
  };

  const recurringQuotesFlag = featureFlags[FeatureFlags.CUST_QUOTES_RECURRING_OPTIONS];
  if (recurringQuotesFlag?.enabled) {
    defaultValues.cycle = SCHEDULE_CYCLE_MONTHLY;
  }

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, submitCount },
    reset,
  } = useForm<LeafRemovalFormData>({
    defaultValues,
  });

  const submit = async (data: LeafRemovalFormData) => {
    const { debris = [], locations = [], images, ...newFormData } = data;

    const result = await dispatch(
      instantQuote_generateQuote({
        property_id: propertyId as string,
        type: InstantQuoteTypes.LeafRemoval,
        pendingQuote: {
          ...newFormData,
          ...checkboxParser(debris, options.debrisTypes),
          locations: checkboxParser(locations, ExtendedYardLocations),
          pendingImages: images,
        },
      }),
    );

    if (result.success) {
      return setShowQuote(true);
    }
  };

  const approve = async () => {
    const result = await dispatch(instantQuote_acceptQuote({ property_id: propertyId, source }));

    if (result?.success) {
      reset();
      navigate(WebRoutes.services, { options: { replace: true } });
    }
  };

  const reject = () => {
    feedbackModal.show({
      isAnswerRequired: false,
      origin: 'instant_quote_leaf_removal_form',
      title: t('instantQuote.viewer.rejectFeedback.title'),
      question: t('instantQuote.viewer.rejectFeedback.reason'),
      questionPlaceholder: t('forms.enterDetailsHere'),
      buttonText: t('instantQuote.viewer.rejectButton'),
      onSubmit: onSubmitFeedback,
    });
  };

  const onSubmitFeedback = async ({ answer }: { answer?: string }) => {
    const result = await dispatch(
      instantQuote_rejectQuote({
        formData: {
          acceptable_price: 0,
          notes: _.isEmpty(answer) ? null : answer,
        },
        property_id: propertyId,
      }),
    );

    if (result?.success) {
      navigate(WebRoutes.browseRedirect, { options: { replace: true } });
    }
  };

  useEffect(() => {
    showQuote && scrollToBottom();
  }, [showQuote]);

  useAutoScrollOnFormErrors({ errors, submitCount });

  return (
    <StyledLeafRemovalWrapper>
      <OrderServiceHeader
        section={t('instantQuoteFor')}
        serviceName={t('order.leafRemoval.title')}
      />

      <form className="flex flex-col gap-6">
        <CheckboxOptionsForm
          options={ExtendedYardLocations}
          control={control}
          title={t('forms.yardLocations')}
          name="locations"
          rules={{ required: true }}
        />

        <RadioOptionsForm
          name="desired_bed_cleanup"
          label={t('leafRemovalForm.question.flowerBed')}
          options={options.desired_bed_cleanup}
          control={control}
          variant={RadioOptionVariant.Flat}
          noPadding={true}
          rules={{
            validate: (value: boolean | string) =>
              value === true || value === false || t('forms.required'),
          }}
        />

        <CheckboxOptionsForm
          options={options.debrisTypes}
          control={control}
          title={t('leafRemovalForm.question.whatDebris')}
          name="debris"
          rules={{ required: true }}
        />

        {watch()['debris']?.includes('has_other') && (
          <TextInput
            placeholder={t('forms.enterDetailsHere')}
            name={'other_debris'}
            control={control}
            rules={{ required: true }}
          />
        )}

        <RadioOptionsForm
          name="desired_haulaway"
          label={t('leafRemovalForm.question.haulAway')}
          options={options.desired_haulaway}
          control={control}
          variant={RadioOptionVariant.Flat}
          noPadding={true}
          rules={{
            validate: (value: boolean | string) =>
              value === true || value === false || t('forms.required'),
          }}
        />

        <RadioOptionsForm
          name="last_service"
          label={t('leafRemovalForm.question.lastRemoval')}
          options={options.last_service}
          control={control}
          variant={RadioOptionVariant.Flat}
          noPadding={true}
          rules={{ required: true }}
        />

        {recurringQuotesFlag?.enabled && (
          <RadioOptionsForm
            name="cycle"
            label={t('leafRemovalForm.question.howOften')}
            options={options.frequency}
            control={control}
            variant={RadioOptionVariant.Flat}
            noPadding={true}
            rules={{ required: true }}
          />
        )}

        <TextArea
          label={t('leafRemovalForm.question.anyDetails')}
          name="details"
          placeholder={t('leafRemovalForm.question.taPlaceholder')}
          control={control}
        />

        <ImageUploadInput
          name="images"
          control={control}
          isUploading={false}
          singleUpload={false}
          label={t('forms.uploadPhotos')}
          uploadText={t('forms.upload.uploading')}
          addButton={{ text: t('forms.upload.placeholder') }}
          tooLargeFilesText={t('forms.upload.fileTooLarge')}
        />

        {!showQuote && (
          <Button
            trackID="submit"
            mode="contained"
            onPress={handleSubmit(submit)}
            loading={instantQuote?.loadingStatus.isLoading}
            disabled={showQuote}
          >
            <Text style={[style.submitBtn, style.boldFont]}>{t('generateQuote')}</Text>
          </Button>
        )}
      </form>

      {instantQuote?.currentQuote && showQuote && (
        <GeneratedInstantQuoteViewer
          property_id={toNumber(propertyId)}
          currentQuote={instantQuote!.currentQuote}
          onApprove={approve}
          onReject={reject}
          isUpdating={instantQuote!.loadingStatus.isUpdating}
        />
      )}
    </StyledLeafRemovalWrapper>
  );
}
